import React from 'react';
import Layout from '../components/layout/Layout';
import CustomersEdit from '../components/customers/Edit';

export default (props) => {
  const { uuid } = props.params;

  return (
    <Layout page="signups">
      <CustomersEdit uuid={uuid} />
    </Layout>
  );
};
