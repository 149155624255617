import React, { useState, useEffect } from 'react';
import { CircularProgress, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { navigate } from 'gatsby';
import * as API from '../../services/api';

import Form from './Form';

const useStyles = makeStyles((theme) => ({
  nav: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(3),

    '& .MuiTypography-root': {
      flexGrow: 1,
    },
  },
}));

export default function CustomersNew(props) {
  const { uuid } = props;
  const classes = useStyles();

  const [customer, setCustomer] = useState(null);

  useEffect(() => {
    if (!customer) {
      API.getCustomer(uuid).then((newCustomer) => {
        if (newCustomer) {
          setCustomer(newCustomer);
        }
      });
    }
  }, [uuid, customer]);

  const onSubmit = async (values) => {
    await API.updateCustomer(uuid, values);
    navigate(`/signups/${uuid}`);
  };

  if (!customer) {
    return <CircularProgress />;
  }

  return (
    <>
      <Paper className={classes.nav}>
        <Typography variant="h6">Update Customer</Typography>
      </Paper>
      <Form isNew={false} initialValues={customer} onSubmit={onSubmit} />
    </>
  );
}
